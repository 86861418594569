import { useState, useEffect,useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from '../hooks/useModal';
import fotoDefault from '../assets/images/productoDefault.jpg';
import { AuthFetch, Fetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';

const categoriaDefault = {
    CodCategoria: 0,
    Nombre: "",
    Descripcion: ""
}

const urlCategoria = process.env.REACT_APP_PROMETHEUS_API + '/categorias' 

export const useCategorias = () => {
    const { stateUser, signOut } = useContext(UserContext)

    const [ categorias, setCategorias] = useState([]);
    const [ categoria, setCategoria ] = useState(categoriaDefault);
    const [ isOpenModal, openModal, closeModal ] = useModal();
    const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();

    useEffect(() => {
        listarCategorias();
    }, []);

    const listarCategorias = async () => {
        const response = await AuthFetch({
            url: urlCategoria + '?'+ new URLSearchParams({
                cod_empresa : 2
            })
        });
        if (response.isValid) {
            setCategorias(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const obtenerCategoria = async (codCategoria) => {
        if (codCategoria) {
            const response = await AuthFetch({
                url: urlCategoria + '/obtenerCategoria?'+ new URLSearchParams({
                    cod_categoria : codCategoria,
                    cod_empresa : 2,
                })
            });
            if (response.isValid) {
                setCategoria(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setCategoria(categoriaDefault);
        }
        openModal();
    }

    const categoriaEliminar = async (codCategoria) =>{
        if (codCategoria) {
            const response = await Fetch({
                url:urlCategoria + `/categorias/${codCategoria}`
            });

            if (response.isValid) {
                setCategoria(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setCategoria(categoriaDefault);
        }
        openModalEliminar();
    }

    const eliminarCategoria = async (codCategoria)=>{
        const response = await Fetch({
            url: urlCategoria + `/categorias/${codCategoria}`,
            method:'DELETE'
        })
        notify(response.content, response.isValid? 'success': 'error' )
        if (response.isValid) {
            listarCategorias()
        }
        closeModalEliminar()
    }

    const guardarEditarCategoria = async () => {
        const esGuardar = categoria.CodCategoria <= 0;
        const response = await AuthFetch({
            url: urlCategoria,
            method: esGuardar ? 'POST' : 'PUT',
            body: JSON.stringify({
                ...(!esGuardar && { CodCategoria: categoria.CodCategoria }),
                // CodCategoria: categoria.CodCategoria,
                Nombre:categoria.Nombre,
                Descripcion: categoria.Descripcion,
                codEmpresa : stateUser.codEmpresa
            })
        });

        notify(response.content, response.isValid ? 'success' : 'error');
        if (response.isValid) {
            setCategoria(categoriaDefault);
            await listarCategorias();
            closeModal()
        }
    }

    const editarValorCategoria = (key, value) => {
        setCategoria(categoria => {
            return {
                ...categoria,
                [key]: value
            }
        });
    }

    return { categoria, listarCategorias, categorias, editarValorCategoria, obtenerCategoria,
            isOpenModal, closeModal,eliminarCategoria, isOpenModalEliminar, 
            closeModalEliminar, categoriaEliminar,guardarEditarCategoria }

}