import React, { useEffect } from 'react';
import '../../assets/css/views/login.css'
import {ProductCard} from '../components/ProductCard';
import {
    useHistory
} from "react-router-dom";

import { useProductos } from '../../hooks/useProductos';

export const Tienda = ({ match  }) => {
    const { params } = match;
    const { codigo, categoria } = params;
    const {        
        producto, 
        listarProductos, 
        guardarProductoConFoto,
        productos,
        editarValorProducto,
        obtenerProducto,
        isOpenModal,
        closeModal,
        fotoUrlProducto,
        fotoUrlProducto2,
        fotoUrlProducto3,
        setFotoFile,
        setFotoFile2,
        setFotoFile3,
        setFotoUrlProducto,
        setFotoUrlProducto2,
        setFotoUrlProducto3,
        eliminarProducto,
        isOpenModalEliminar, 
        closeModalEliminar,
        productoEliminar,
        listarProductosPorCategoria 
    } = useProductos()




    useEffect(()=>{
        listarProductosPorCategoria(codigo)
    },[codigo])




    return (
        <>
            <div>
                Product page {categoria} {codigo}


                




                <div className="containerScroll h-[75%] mt-2 relative top-0 bottom-0 left-0 right-0 contenedor-tabla">

                    <div className="flex flex-wrap lg:grid-cols-1 lg:grid justify-center lg:gap-2 gap-4">
                    {
                        productos.length > 0 ?
                        productos.map((producto) => (
                            <>

                                <ProductCard 
                                    key={producto.CodProducto}
                                    id={producto.CodProducto}
                                    nombre={producto.Nombre} 
                                    precio={parseFloat(producto.PrecioVenta).toFixed(2)} 
                                    Foto1={producto.Foto1}
                                    Foto2={producto.Foto2}
                                    Foto3={producto.Foto3}
                                    descripcion={producto.Descripcion}
                                />

                                

                                {/* <div className='border p-3 lg:p-2 w-[250px] justify-center rounded-xl border-gray-300 border flex h-[400px] bg-white shadow-xl'>
                                    <div className=''>
                                        <img className='w-[200px] h-[250px] rounded object-cover ' 
                                            src={producto.Foto1} alt='' />
                                    <div className='flex flex-col justify-between ml-4 text-gray-800'>
                                        <div className='flex items-center mb-2'>
                                            <span className='text-lg font-bold'>{producto.Nombre}</span>
                                        </div>
                                        <div className='flex items-center mb-2'>
                                            <span className='text-lg font-bold'>S/ {parseFloat(producto.PrecioVenta).toFixed(2)}</span>
                                        </div>

                                            <button onClick={()=>console.log("ClICK",producto.CodProducto, producto.Nombre)} className="text-white bg-green-600 hover:bg-gree-500 text-sm px-3 py-1 rounded mr-2">
                                                <i className="fa fa-cart-plus mr-1"></i>
                                                Agregar Carrito
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                            </>
                        ))
                        :
                        <div className="p-4 text-center text-gray-600">No hay productos con esta categoría</div>
                    }
            {/* {
                productos.length > 0 ?
                productos.map((producto) => (
                    <div className='border p-3 lg:p-2 w-[45%] lg:w-full rounded-xl border-gray-300 border flex h-[200px] bg-white shadow-xl'>
                        <div className='flex-shrink-0'>
                            <img className='w-[250px] lg:w-[150px] h-full object-cover rounded-l-xl' 
                                src={producto.Foto1} alt='' />
                        </div>
                        <div className='flex flex-col justify-between ml-4 text-gray-800'>
                            <div className='flex items-center mb-2'>
                                <i className='fas fa-car text-blue-500 mr-2'></i>
                                <span className='text-lg font-bold'>{producto.Nombre}</span>
                            </div>
                            <div className='flex items-center mb-2'>
                                <i className='fa fa-imdb text-blue-500 mr-2'></i>
                                <span>AJO-497</span>
                            </div>
                            <div className='flex items-center mb-2'>
                                <i className='fas fa-car-side text-blue-500 mr-2'></i>
                                <span>SEDAN</span>
                            </div>
                            <div className='flex items-center mb-2'>
                                <i className='fas fa-gas-pump text-blue-500 mr-2'></i>
                                <span>GASOLINA</span>
                            </div>
                            <div className='flex items-center mb-2'>
                                <i className='fas fa-tint text-blue-500 mr-2'></i>
                                <span>ROJO</span>
                            </div>
                            <div className='flex'>
                                <button onClick={{}} className="text-white bg-blue-500 hover:bg-blue-600 text-sm px-3 py-1 rounded mr-2">
                                    <i className="fas fa-eyes mr-1"></i> Agregar
                                </button>
                                <button onClick={{}} className="text-white bg-red-500 hover:bg-red-800 text-sm px-3 py-1 rounded">
                                    <i className="fas fa-trash mr-1"></i> Eliminar
                                </button>
                            </div>
                        </div>
                    </div>
                ))
                :
                <div>no hay productos con esta categoria</div>
            } */}


{/* <div key={producto.id} className='border p-4 rounded-xl border-red-500 border bg-white shadow-md'> */}

   
    
  
    

    
</div>

    
    
</div>




















            </div>
        </>
    )
}
