import React, { useState, useEffect, useRef } from 'react'
import '../../../assets/css/layoutMaster.css'

import { Menu } from './Menu'
import { Header } from './Header'
import { Footer } from './Footer'
import { useMenu } from '../../../hooks/useMenu'
import { ImageSlider } from './ImageSlider'


export const LayoutMaster = ({children}) => {
    
    const images = [
        'https://kcohouse.com/cdn/shop/articles/Poster_1800_x_1200_1_2000x.png?v=1632010715',
        'https://libbys.es/wordpress/wp-content/uploads/2014/12/frutas-con-vitamina-C.jpg',
        'https://someicca.com.mx/wp-content/uploads/AdobeStock_117494948-post-vitamina-C-1629x1079-1.jpeg'
        // Agrega más imágenes según sea necesario
      ];

    return (
        <>
            <div className="dashboard overflow-hidden">
                <Header />
                <div className="content overflow-auto containerScroll">
                    <Menu />
                    {/* <ImageSlider images={images} /> */}

                    <div className="w-full h-full relative">
                        {children}
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}