import React, { useContext, useState, useLazyRef, lazy, useMemo, useEffect } from 'react'
import { Switch, Route} from "react-router-dom";
import { UserContext } from '../../context/provider/UserProvider';

import { LayoutMaster } from '../modules/layout/LayoutMaster';
import { PrivateRoute } from '../components/PrivateRoute';


import PageNotFound from '../pages/PageNotFound';


export function PrivateRoutes({ token }) {
    // const { stateUser } = useContext(UserContext);

    return (
        <>
            {
                token !== ''  && (
                    <LayoutMaster>
                        <Switch>
                            <PrivateRoute path="*" component={PageNotFound} />
                        </Switch>
                    </LayoutMaster>
                )
            }
        </>
    )
}