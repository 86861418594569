// ImageSlider.js

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute hover:text-white text-[50px] top-1/2 right-0 transform -translate-y-1/2 text-gray-800  p-2 rounded-full"
  >
    {'>'}
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute hover:text-white text-[50px] z-50 top-1/2 left-0 transform -translate-y-1/2 text-gray-800  p-2 rounded-full"
  >
    {'<'}
  </button>
);

export const ImageSlider = ({ images }) => {


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true, 
    autoplaySpeed: 3000,
  };




  return (
    <div className="relative">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="relative">
            <img src={image} alt={`Slide ${index + 1}`} className="w-full h-[400px]" />
          </div>
        ))}
      </Slider>
    </div>
  );










  return (
    <div className="w-full">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="relative">
            <img src={image} alt={`Slide ${index + 1}`} className="w-full h-auto" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

