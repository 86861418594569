import React, { useState, useRef, useEffect } from 'react';
import $ from 'jquery';


export const ProductCard = (props) => {
  
  const [isFlipped, setIsFlipped] = useState(false);
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);
  const productCardRef = useRef(null);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };
  

  const productId = props.id



  const handleMouseEnter = () => {
    console.log("handleMouseEnter ENTRA")
    productCardRef.current.classList.add('animate');

    // ... otras acciones en mouse enter
  };

  const handleMouseLeave = () => {
    console.log("handleMouseLeave SALE" )

    productCardRef.current.classList.remove('animate');

    // ... otras acciones en mouse leave
  };
  const handleViewDetails = () => {
    console.log()
    // Implementa la lógica para ver detalles
    // Puedes usar el estado local para manejar la visibilidad de la vista frontal y trasera
  };

  const handleFlipBack = () => {
    // Implementa la lógica para volver a la vista frontal
  };
  

  $(document).ready(function(){
	
    // Lift card and show stats on Mouseover
    $('#product-card').hover(function(){
        $(this).addClass('animate');
        $('div.carouselNext, div.carouselPrev').addClass('visible');			
      }, function(){
        $(this).removeClass('animate');			
        $('div.carouselNext, div.carouselPrev').removeClass('visible');
    });	
    
    // Flip card to the back side
    $('#view_details').click(function(){		
      $('div.carouselNext, div.carouselPrev').removeClass('visible');
      $('#product-card').addClass('flip-10');
      setTimeout(function(){
        $('#product-card').removeClass('flip-10').addClass('flip90').find('div.shadow').show().fadeTo( 80 , 1, function(){
          $('#product-front, #product-front div.shadow').hide();			
        });
      }, 50);
      
      setTimeout(function(){
        $('#product-card').removeClass('flip90').addClass('flip190');
        $('#product-back').show().find('div.shadow').show().fadeTo( 90 , 0);
        setTimeout(function(){				
          $('#product-card').removeClass('flip190').addClass('flip180').find('div.shadow').hide();						
          setTimeout(function(){
            $('#product-card').css('transition', '100ms ease-out');			
            $('#cx, #cy').addClass('s1');
            setTimeout(function(){$('#cx, #cy').addClass('s2');}, 100);
            setTimeout(function(){$('#cx, #cy').addClass('s3');}, 200);				
            $('div.carouselNext, div.carouselPrev').addClass('visible');				
          }, 100);
        }, 100);			
      }, 150);			
    });			
    
    // Flip card back to the front side
    $('#flip-back').click(function(){		
      
      $('#product-card').removeClass('flip180').addClass('flip190');
      setTimeout(function(){
        $('#product-card').removeClass('flip190').addClass('flip90');
    
        $('#product-back div.shadow').css('opacity', 0).fadeTo( 100 , 1, function(){
          $('#product-back, #product-back div.shadow').hide();
          $('#product-front, #product-front div.shadow').show();
        });
      }, 50);
      
      setTimeout(function(){
        $('#product-card').removeClass('flip90').addClass('flip-10');
        $('#product-front div.shadow').show().fadeTo( 100 , 0);
        setTimeout(function(){						
          $('#product-front div.shadow').hide();
          $('#product-card').removeClass('flip-10').css('transition', '100ms ease-out');		
          $('#cx, #cy').removeClass('s1 s2 s3');			
        }, 100);			
      }, 150);			
      
    });	
  
    
    /* ----  Image Gallery Carousel   ---- */
    
    var carousel = $('#carousel ul');
    var carouselSlideWidth = 335;
    var carouselWidth = 0;	
    var isAnimating = false;
    
    // building the width of the casousel
    $('#carousel li').each(function(){
      carouselWidth += carouselSlideWidth;
    });
    $(carousel).css('width', carouselWidth);
    
    // Load Next Image
    $('div.carouselNext').on('click', function(){
      var currentLeft = Math.abs(parseInt($(carousel).css("left")));
      var newLeft = currentLeft + carouselSlideWidth;
      if(newLeft == carouselWidth || isAnimating === true){return;}
      $('#carousel ul').css({'left': "-" + newLeft + "px",
                   "transition": "300ms ease-out"
                 });
      isAnimating = true;
      setTimeout(function(){isAnimating = false;}, 300);			
    });
    
    // Load Previous Image
    $('div.carouselPrev').on('click', function(){
      var currentLeft = Math.abs(parseInt($(carousel).css("left")));
      var newLeft = currentLeft - carouselSlideWidth;
      if(newLeft < 0  || isAnimating === true){return;}
      $('#carousel ul').css({'left': "-" + newLeft + "px",
                   "transition": "300ms ease-out"
                 });
        isAnimating = true;
      setTimeout(function(){isAnimating = false;}, 300);			
    });
  });





  return (

      <>
        <div
          ref={productCardRef}
          className={`product-card ${isFlipped ? 'flipped' : ''}`}
          onMouseEnter={()=>handleMouseEnter}
          onMouseLeave={()=>handleMouseLeave}
        >
            <div id="make-3D-space">
                <div id="product-card">
                    <div id="product-front">
                      <div className="shadow"></div>
                        <img src={props.Foto1} alt="" />
                        <div className="image_overlay"></div>
                        <div id="view_details">Ver Detalles</div>
                        <div className="stats">        	
                            <div className="stats-container">
                                <span className="product_price">S/{props.precio || '000.00'}</span>
                                <span className="product_name">{props.nombre|| 'Nombre Producto'}</span>    
                                <p>{props.descripcion || 'Descripcion Del producto'}</p>        
                                <div className='w-full text-center'>
                                  
                                  <button className='bg-green-600 hover:bg-green-500 p-2 text-white text-center'>
                                    <i className='fa fa-cart-plus mx-2'/>
                                    Agregar al carrito
                                  </button>
                                </div>                                    
                            </div>                         
                        </div>
                    </div>
                    <div id="product-back">
                      <div className="shadow"></div>
                        <div id="carousel">
                            <ul>
                                {/* <li><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaK8EoHg92DdZlpR4U2yJnMDIZZjmgRFh8LmoFGgF3-PzhAQU0cIyRMh-VZYcWit9a69Q&usqp=CAU" alt="" /></li> */}
                                <li><img src={props.Foto1} alt="" /></li>
                                <li><img src={props.Foto2} alt="" /></li>
                                <li><img src={props.Foto3} alt="" /></li>
                            </ul>
                            <div className="arrows-perspective">
                                <div className="carouselPrev">
                                    <div className="y"></div>
                                  <div className="x"></div>
                                </div>
                                <div className="carouselNext">
                                    <div className="y"></div>
                                  <div className="x"></div>
                                </div>
                            </div>
                        </div>
                        <div id="flip-back">
                          <div id="cy"></div>
                            <div id="cx"></div>
                        </div>
                    </div>	  
                </div>	
            </div>
            </div>
</>
  );
};

