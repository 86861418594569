import React, { useState, useEffect, useContext, useRef } from 'react';

import { UserContext } from '../../../context/provider/UserProvider';
import { useHistory } from "react-router-dom";
import logo from '../../../assets/images/logoPrometheuslargo.png'

export const Header = () => {


    return (
        <>
            <div className="  header z-50 " >
                <div className='flex w-full sticky justify-between'>
                    {/* <div className="flex justify-between  width-full justify-center items-center "> */}
                        
                        <div className="flex " >
                            <img  className="w-[300px]" src={logo} alt="Workflow"/>
                        </div>

                        <div className='flex '>
                            <input placeholder='¿Qué estás buscando?' className='px-2 border border-gray-400 rounded-xl w-[500px]'></input>
                            <i className='fa fa-search text-2xl mx-1 text-orange-500'/>

                        </div>

                        <div className='flex  w-[150px] mr-5'  >
                            <i className='fa fa-user text-xl mx-1 text-green-700'/>Iniciar Sesion
                            <i className='fa fa-shopping-cart text-2xl mx-1 text-orange-500'/>
                        </div>
                    </div>
                </div>
            {/* </div>   */}
        </>
    )
        
}