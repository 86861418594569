// HorizontalMenu.js

import React from 'react';
import { Link, useHistory  } from 'react-router-dom'; // Importa Link si estás usando react-router
import { useCategorias } from '../../../hooks/useCategorias';

export const Menu = () => {
  const { categoria, listarCategorias, categorias, editarValorCategoria, obtenerCategoria, isOpenModal, closeModal,eliminarCategoria, isOpenModalEliminar, closeModalEliminar, categoriaEliminar,guardarEditarCategoria} = useCategorias()

  const history = useHistory() 

  // const handleCategoriaClick = (categoriaNombre, categoriaCodigo) => {
  //   console.log("INGRESO EL categoriaNombre", categoriaNombre,categoriaCodigo )
  //   history.push(`/tienda/${categoriaNombre}`, { categoriaCodigo });
  // };




  return (
    <nav className="horizontal-menu">
        <ul className="text-l"> {/* Agrega la clase text-xl para aumentar el tamaño de la fuente */}
          <li><Link to="/">Inicio</Link></li>

            {categorias.map((categoria,i ) => (
                      // <li className='border-l'><Link to={`/tienda/${categoria.Nombre}`}>{categoria.Nombre}</Link></li>

              <Link to={`/tienda/${categoria.CodCategoria}/${categoria.Nombre}`}>
                {categoria.Nombre}
              </Link>
                      // <li className='border-l' onClick={() => handleCategoriaClick(categoria.Nombre,categoria.CodCategoria)}>{categoria.Nombre}</li>
            ))}

          {/* Agrega más elementos según sea necesario */}
        </ul>
    </nav>
  );
};

