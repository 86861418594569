import React from 'react';
import '../../assets/css/views/login.css'
import { useHistory } from "react-router-dom";
import { ImageSlider } from '../modules/layout/ImageSlider';

export const Web = () => {
    const history = useHistory();

    // const irALogin = async () => {
    //     history.push('/Login')
    // }



    const images = [
        'https://kcohouse.com/cdn/shop/articles/Poster_1800_x_1200_1_2000x.png?v=1632010715',
        'https://libbys.es/wordpress/wp-content/uploads/2014/12/frutas-con-vitamina-C.jpg',
        'https://someicca.com.mx/wp-content/uploads/AdobeStock_117494948-post-vitamina-C-1629x1079-1.jpeg'
        // Agrega más imágenes según sea necesario
    ];



    return (
        <>
            <div className='bg-white border w-full h-full'>
                <ImageSlider images={images} />

            </div>
        </>
    )
}
