import React from 'react';
import '../../assets/css/loading.css'


export const Loading = () => {
    
    return (
            <>
            <div className='loading-overlay'>

                <div className="fire">
                    {/* <div className="fire-left">
                        <div className="main-fire"></div>
                        <div className="particle-fire"></div>
                    </div> */}
                    <div className="fire-center">
                        <div className="main-fire"></div>
                        <div className="particle-fire"></div>
                        <div className="particle-fire2"></div>
                        <div className="particle-fire3"></div>
                    </div>
                    {/* <div className="fire-right">
                        <div className="main-fire"></div>
                        <div className="particle-fire"></div>
                    </div> */}

                    <div className="fire-bottom">
                        <div className="main-fire"></div>
                    </div>
                    <div className=' textCargando ' >
                        <span>C</span>
                        <span>a</span>
                        <span>r</span>
                        <span>g</span>
                        <span>a</span>
                        <span>n</span>
                        <span>d</span>
                        <span>o</span>
                        <span>...</span>
                    </div>
                </div>
            </div>

            </>
        )
}