import React, { useContext } from 'react'
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import {Web} from '../pages/Web'
import { Login } from '../pages/Login'
import { LayoutMaster } from '../modules/layout/LayoutMaster';
import { Tienda } from '../pages/Tienda';
export function PublicRoutes() {
    return (
        <>
            <LayoutMaster>
                <Switch>             
                    <Route exact path='/' component={Web} />  
                    <Route
                        exact
                        path='/tienda/:codigo/:categoria'
                        render={(props) => <Tienda {...props} />}
                    />                    
                    <Route exact path='/Login' component={Login} />  
                </Switch>
            </LayoutMaster>
        </>
    )
}